<template>
  <div class="flex flex-col text-zinc-950">
    <div class="flex flex-col relative">
        <video
            ref="video"
            class="h-screen object-cover overflow-hidden"
            poster="https://foresightmanifoldstg.blob.core.windows.net/public-frontend/imgs/header.png"
            autoplay muted playsinline loop
        >
            <source
                src="https://foresightmanifoldstg.blob.core.windows.net/public-frontend/videos/header.webm"
                type="video/mp4"
            >
        </video>
        <!-- <spline-viewer
            class="h-screen object-cover overflow-hidden"
            url="https://prod.spline.design/miIdskDeSgtmim8O/scene.splinecode"
        /> -->
        <div
            class="w-full h-[80%] absolute bottom-0"
            style="background: linear-gradient(
              0deg,
              rgba(0,0,0,1) 0%,
              rgba(0,0,0,0.3) 35%,
              rgba(0,0,0,0) 100%
            );"
        />
        <div class="flex flex-col items-center justify-center absolute inset-0">
            <h1 class="text-5xl md:text-9xl font-bold uppercase text-zinc-50 
                font-manifold tracking-widest">
                Manifold
            </h1>
            <h3 class="text-xl md:text-3xl font-bold text-zinc-50 font-sans
                text-center mb-4">
                Fleet Safety Through Telematics, At Scale
            </h3>
            <!-- Show play/pause button for iOS -->
            <button
                v-if="browser.os === 'iOS'"
                class="outline-none bg-transparent transition-all ease-in-out
                rounded-lg p-4 duration-300 hover:bg-zinc-50/10"
                @click="togglePlay"
            >
                <svg
                    v-if="!playing"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    class="fill-white w-16 h-16"
                >
                    <path d="M0 0h24v24H0z" fill="none"/>
                    <path d="M8 5v14l11-7z"/>
                </svg>
                <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    class="fill-white w-16 h-16"
                >
                    <path d="M0 0h24v24H0z" fill="none"/>
                    <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"/>
                </svg>
            </button>
        </div>
    </div>

    <div class="flex flex-col items-center w-full pt-16 pb-56 px-4 bg-black">
        <h3
            class="text-xl md:text-3xl font-bold text-center font-sans
                max-w-[600px] text-zinc-50 mb-8"
        >
            Unifying Data Across Providers enhancing it with AI-Driven Risk Insights
        </h3>
        <img
            src="https://foresightmanifoldstg.blob.core.windows.net/public-frontend/imgs/diagram.svg"
            class="bg-blend-multiply max-w-[320px] md:max-w-[600px]"
            alt="Manifold diagram"
        />
    </div>
</div>

<div class="flex flex-col items-center bg-zinc-900 p-8">
	<h3
		class="text-xl md:text-3xl font-bold text-center font-sans max-w-[450px]
        text-zinc-50 mb-8"
	>
		Subscribe to Learn More
	</h3>

	<div class="flex flex-row">
		<input
			v-model="email"
			type="text"
			placeholder="Enter your email"
			class="
				p-4 rounded-s-lg outline-none placeholder:font-semibold
				font-semibold bg-zinc-50/10 focus:bg-zinc-50/20 border-none
				transition-all ease-in-out
			"
		/>
		<button
			:class="twMerge(
				'p-4 rounded-e-lg border-none outline-none font-semibold min-w-max\
				transition-all ease-in-out',
				email.trim().length === 0
                    ? 'bg-zinc-100/30 hover:bg-zinc-200/40 text-zinc-50 \
                        cursor-not-allowed'
                    : 'bg-lime-500 hover:bg-lime-600 text-zinc-950'
			)"
			:disabled="email.trim().length === 0"
            @click="subscribe"
		>
			Sign Up
		</button>
	</div>
</div>
</template>

<script>
import { detect } from 'detect-browser';
import { subscriptions } from '$firebase';
import { twMerge } from 'tailwind-merge';

export default {
	data() {
		return {
			email: '',
            loading: false,
            playing: false,
            twMerge,
		};
	},
    computed: {
        browser() {
            return detect();
        },
    },
    methods: {
        async subscribe() {
            if (this.loading) return;
            this.loading = true;
            await subscriptions.subscribe({
                email: this.email,
            });
            this.email = '';
            this.loading = false;
        },
        togglePlay() {
            if (this.playing) {
                this.$refs.video.pause();
                this.playing = false;
                return;
            }
            this.playing = true;
            this.$refs.video.play();
        },
    },
};
</script>