import { collection, doc, setDoc } from "firebase/firestore";
import { db } from "./_globals";

export const subscribe = async (context) => {
    const ref = doc(collection(db, 'subscriptions'));
    await setDoc(ref, {
        email: context.email,
        created_at: new Date(),
    });
};

export default {
    subscribe,
};